import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

import Layout from "layouts/Layout";

import PageTitle from "components/PageTitle";

const PublicBlankPage = ({ title, link, to, children }) => (
  <Layout metaTitle={title}>
    <PageTitle title={title} />
    <p>{children}</p>
    {link && (
      <Link style={{ paddingBottom: 30 }} to={to}>
        {link}
      </Link>
    )}
  </Layout>
);

PublicBlankPage.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default PublicBlankPage;

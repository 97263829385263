import React from "react";

import PublicBlankPage from "templates/PublicBlankPage";

const LegalNotePage = () => (
  <PublicBlankPage title="Términos y Condiciones" link="Volver a home" to="/">
    <p>
      La Vilaine.es es un fabricante de Moda de Playa única y exclusiva.
      Vendemos ropa de verano al por mayor a tiendas y distribuidores.
      LaVilaine.es es una marca registrada en el territorio español. En este
      portal web exponemos nuestros productos y catálogo de ropa de playa y moda
      de verano, cumpliendo con la normativa LOPD/LSSI-CE; para su tranquilidad
      le informamos que la única información que recogeremos de su visita será
      voluntaria, en caso de que usted decida crear una cuenta de usuario.
    </p>
    <h3>NOTA LEGAL</h3>
    <p>
      “En cumplimiento con lo dispuesto en la Ley 34/2002 de Servicios de la
      Sociedad de la Información y de Comercio Electrónico LSSI le informamos
      que Bernard Cerezo, el propietario de La Vilaine.es, fija como domicilio
      de su actividad la siguiente dirección: C/ Nectarina, 3 - Urb. El Limonar,
      29700 Vélez-Málaga (Málaga, España), NIF X3049926B y dirección de correo
      electrónico principal info (arrob@) lavilaine.es. El nombre comercial y su
      logotipo son marcas que están siendo registradas.”
    </p>
    <h3>CLÁUSULA DE PRIVACIDAD</h3>
    <p>
      “En cumplimiento con lo dispuesto en la Ley Orgánica 15/1999 de Protección
      de Datos de Carácter Personal le informamos que los datos personales que
      nos facilite serán incorporados a un fichero, debidamente inscrito en la
      Agencia Española de Protección de Datos, responsabilidad de Bernard
      Cerezo, con el fin de administrar y gestionar todos los procesos
      necesarios para el buen servicio de los solicitantes de información de la
      empresa. A nuestros clientes les informamos además que sus datos
      personales podrán ser cedidos a las autoridades públicas competentes que
      lo requieran de conformidad con las disposiciones legales y reglamentarias
      aplicables al caso. Por último, le informamos además que tiene la
      posibilidad de ejercer sus derechos de acceso, rectificación, cancelación
      y especificación, en los términos establecidos en la legislación vigente,
      dirigiéndose por escrito a La Vilaine.es, C/ Nectarina, 3 - Urb. El
      Limonar, 29700 Vélez-Málaga (Málaga, España), o por coreo electrónico a
      info (arrob@) lavilaine.es.”
    </p>
  </PublicBlankPage>
);

export default LegalNotePage;
